require('./loading-spinner.scss');

export default class LoadingSpinner {
  static start() {
    if (0 === $('.loading-spinner').length) {
      $('body').append('<div class="loading-spinner"></div>');
    }
  }

  static stop() {
    if (0 < $('.loading-spinner').length) {
      $('.loading-spinner').remove();
    }
  }
}

