class RelationLink {
  object;
  object_cardinality_min;
  object_cardinality_max;

  subject;
  subject_cardinality_min;
  subject_cardinality_max;

  verb;
}

class Concept {
  name;
}

class Ciao {
  static BASE_URL = "https://ciao.balab.fr/api/v2/";

  /**
   *
   * @param rawString string
   * @returns Promise
   */
  extractConcepts(rawString) {
    return this.call('extract-concepts', {
      input: rawString
    });
  }

  binarySentence(rawString) {
    return this.callLocal('/capturator/binary-sentence-handle', {
      input: rawString
    });
  }
  extractFonction(rawString) {
    return this.callLocal('/capturator/fonction-extract', {
      input: rawString
    });
  }

  /**
   *
   * @param rawString string
   * @returns Promise
   */
  checkGrammar(rawString) {
    return this.call('check-grammar', {
      input: rawString
    });
  }

  call(action, data) {
    return new Promise(function (resolve, reject) {
      $.post({
        url:         Ciao.BASE_URL + action + '?debug=true',
        type:        'post',
        dataType:    'json',
        contentType: 'application/json',
        data:        JSON.stringify(data),
        success:     resolve,
        error:       reject
      })
    })
  }

  callLocal(action, data) {
    return new Promise(function (resolve, reject) {
      $.post({
        url:         action,
        type:        'post',
        dataType:    'json',
        contentType: 'application/json',
        data:        JSON.stringify(data),
        success:     resolve,
        error:       reject
      })
    })
  }
}


export default new Ciao();