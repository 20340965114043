/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you require will output into a single css file (app.css in this case)
require('../scss/app.scss');


global.LoadingSpinner = window.LoadingSpinner = document.LoadingSpinner = require('./helper/loading-spinner').default;
global.Ciao = window.Ciao = document.Ciao = require('./api/ciao').default;
global.$ = window.$ = document.$ = require('jquery');
require('bootstrap/dist/js/bootstrap.js');